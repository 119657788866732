import React, { useEffect, useState, useCallback } from 'react';
import { getPotsRequest } from "../../api/post";
import { getCuentasRequest } from "../../api/cuenta";
import { getJobsRequest } from "../../api/job";

import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addCont } from "../../features/cuentas/cuentaSlide";
import { addJob } from "../../features/jobs/jobSlide";

import { useVariables } from "../../context/VariablesContext";
import { addPost } from "../../features/post/postSlide";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import { postQueryPostRequest } from "../../api/post";
import { getVistasRequest } from "../../api/vista";
import { getAlarmasRequest } from "../../api/alarma";

import { useAuth } from "../../context/AuthContext";
import {
    AppBar,
    Box,
    Badge,
    IconButton,
    Toolbar,
    Menu,
    MenuItem,
    Button,
    Avatar,
    Divider,
    ListItemIcon,
    Typography,
  } from "@mui/material";
  import userimg from "../../assets/images/users/user.jpg";
  
  export function Header(props) {

    const dispatch = useDispatch(); 
    const { vbateria, variable, getVariables } = useVariables([]);
    const {isAuthenticated, logout, user } = useAuth();
    const navigate = useNavigate(); 
    const [hists, setHits] = useState([]);
    const [vista, setVista] = useState([]);
    const [ alarma, setAlarma] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      navigate(`/mom`);
    };


    const register = () => {
      navigate(`/reg`)
    }

    const irlogout = () => {
      logout(); 
    }




    const handleClose = () => {
      setAnchorEl(null);
    };
    const [anchorEl4, setAnchorEl4] = useState(null);

    const handleClick4 = (event) => {
      setAnchorEl4(event.currentTarget);
    };  
    const handleClose4 = () => {
      setAnchorEl4(null);
    };

    const [anchorEl5, setAnchorEl5] = useState(null);
    const handleClick5 = (event) => {
      setAnchorEl5(event.currentTarget);
    };
    const handleClose5 = () => {
      setAnchorEl5(null);
    };

    useEffect(() => {
      const timer = setInterval( async () => {
        const posts = await getPotsRequest();        
        const postsLenght = (posts.data.length)
        //console.log(postsLenght)
        const postReducido = posts.data.slice((postsLenght,postsLenght-2000))
        //console.log(postReducido)
        dispatch(addPost(postReducido));      
      }, 50000);
      return () => clearInterval(timer);    
    }, []);     



    const fetchData = useCallback(async () => {
      try {
        const [cuentasResponse, vistasResponse, jobsResponse] = await Promise.all([
          getCuentasRequest(),
          getAlarmasRequest(),
          getJobsRequest(),
        ]);
  
        dispatch(addCont(cuentasResponse.data));
        dispatch(addJob(jobsResponse.data));
  
        // Combine and filter vistas with cuentas
        const resultadoFinal = vistasResponse.data.map(vista => {
          const cuenta = cuentasResponse.data.find(c => c.id === vista.id);
          return cuenta ? { ...vista, cuenta } : vista;
        });
  
        const resultadoConUltimasVistas = cuentasResponse.data.map(cuenta => {
          const vistasFiltradas = resultadoFinal.filter(vista => vista.topiccuenta === cuenta.nroorden);
          return vistasFiltradas.length > 0
            ? { ...vistasFiltradas[vistasFiltradas.length - 1], cuenta }
            : null;
        }).filter(item => item !== null);
  
        setAlarma(resultadoConUltimasVistas);
  
        // Fetch posts and set hits data
        const posts = await getPotsRequest();        
                const postsLenght = (posts.data.length)
                //console.log(postsLenght)
                const postReducido = posts.data.slice((postsLenght,postsLenght-2000))
                //console.log(postReducido)
                dispatch(addPost(postReducido));      
  
      } catch (error) {
        console.error("Error en fetchData:", error);
      }
    }, [dispatch]);
  
    useEffect(() => {
      fetchData(); // Initial fetch
      const intervalId = setInterval(fetchData, 10000); // Refresh every 10 seconds
      return () => clearInterval(intervalId); // Cleanup on unmount
    }, [fetchData]);
  

  //borrado de base de datos
  let ms = new Date();
  let day = ms.getDate()-7;  
  let month = ms.getMonth()+1;
  let year = ms.getFullYear();
  if (day < 0) {
    var nday = 30;
    var nmonth = month - 1;
    var nyear = year - 1
  } else {
    var nday = day;
    var nmonth = month;
    var nyear = year
  }
  let date = nyear + '-' + nmonth + '-' + nday

  //console.log(vista)

  const vistaA = alarma.filter(x => x.error !== '' & x.ackA === true)
  //const tablasMon = tablas.filter(x => x.error !== '' & x.ackA === true)
  //console.log('header', vistaA)

    return (
      <AppBar sx={props.sx} elevation={1} className={props.customClass}>
        <Toolbar>
          <Box flexGrow={1} />
           {/* ------------------------------------------- */}
          {/* Notifications Dropdown */}
          {/* ------------------------------------------- */}
          <IconButton
              aria-label="menu"
              color="primary"
              aria-controls="notification-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Badge badgeContent={ vistaA.length } color="error">
                <NotificationsNoneOutlinedIcon width="20" height="20" />
              </Badge>
              
          </IconButton>
          <Menu
            id="notification-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            sx={{
              "& .MuiMenu-paper": {
                width: "200px",
                right: 0,
                top: "70px !important",
              },
            }}
          >
            <MenuItem onClick={handleClose}>Action</MenuItem>
            <MenuItem onClick={handleClose}>Action Else</MenuItem>
            <MenuItem onClick={handleClose}>Another Action</MenuItem>
          </Menu>
          {/* ------------------------------------------- */}
          {/* End Notifications Dropdown */}
          {/* ------------------------------------------- */}
          {/* ------------------------------------------- */}
          {/* Profile Dropdown */}
          {/* ------------------------------------------- */}
          <Box
            sx={{
              width: "1px",
              backgroundColor: "rgba(0,0,0,0.1)",
              height: "25px",
              ml: 1,
            }}
          ></Box>

      { isAuthenticated   ? ((user.role === 'admi') ? 
              <Button
              aria-label="menu"
              color="inherit"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={register}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography color="primary" variant='p' sx={{ flexGrow: 6 }}>
                    {isAuthenticated ?  <p>Register </p> : null }</Typography>
                </Box>
              </Button>
          
          :null): null }      

      { isAuthenticated   ?  
              <Button
              aria-label="menu"
              color="inherit"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={irlogout}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography color="primary" variant='p' sx={{ flexGrow: 6 }}>
                    {isAuthenticated ?  <p>Logout </p> : null }</Typography>
                </Box>
              </Button>
          
          : null }      

          

          <Button
            aria-label="menu"
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleClick4}
          >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography color="primary" variant='p' sx={{ flexGrow: 6 }}>
                  {isAuthenticated ?  <p>{user.username} </p> : null }</Typography>
              </Box>
          </Button>


            
        </Toolbar>
      </AppBar>
    )
  }
  
 
  