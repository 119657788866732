import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Paper, Typography, Card, CardContent } from "@mui/material";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { getCuentaRequest } from "../../api/cuenta";
import { getPostsDashRequest } from '../../api/post';
import { BoxCuenta } from './Box/BoxCuenta';
import { BoxJob } from './Box/BoxJob';
import { BoxSeguridad } from './Box/BoxSeguridad';
import { getVistasRequest } from '../../api/vista';
import Corriente  from './Graph/Corriente';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { pubpush }  from '../../api/mqtt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

export function DashPage() {
  const navigate = useNavigate();  
  const params = useParams();
  const [cuentaVar, setCuentaVar] = useState([]);
  const [gaugeData, setGaugeData] = useState({});
  const [vistas, setVistas] = useState([]);
  const [dateLastUpdated, setDateLastUpdated] = useState();
  const [timeLastUpdated, setTimeLastUpdated] = useState();
  const [fecha, setFecha] = useState('2025-01-25T00:18:19.810+00:00');
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    try {
      // Filter for the relevant account
      const cuentas = await getCuentaRequest(params.id);
      const cuenta = cuentas.data

      if (cuentas.data.length > 0) {
        setIsLoading(false)
      }


      setCuentaVar(cuenta)
      if (!cuenta) {
        console.error("Account not found");
        return;
      }
      
      // Fetch post data using the account's nroorden
      const { data: postData } = await getPostsDashRequest(cuenta.nroorden);
      const { postUnico, postMultiple } = postData[0];
  
      // Prepare data arrays for voltage and current
      const dataMFinal = postMultiple.length;
      const dataMInicial = Math.max(dataMFinal - 15, 0);  // Prevent negative index
  
      const volt = postMultiple.slice(dataMInicial).map(item => item.vbat);
      const i1m = postMultiple.slice(dataMInicial).map(item => item.ic);
      const xvolt = postMultiple.slice(dataMInicial).map(item => {
        const fecha = new Date(item.createdAt);
        const horasArgentina = (fecha.getUTCHours() - 3 + 24) % 24; // Adjust for Argentina timezone
        const horas = horasArgentina.toString().padStart(2, '0');
        const minutos = fecha.getUTCMinutes().toString().padStart(2, '0');
        return `${horas}:${minutos}`;
      });
  
      // Set the latest formatted date
      const fecha = new Date(postMultiple[dataMFinal - 1]?.createdAt);
      const dia = fecha.getUTCDate().toString().padStart(2, '0');
      const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, '0');
      const ano = fecha.getUTCFullYear();
      const fechaFormateada = `${dia}/${mes}/${ano}`;
      setFecha(fechaFormateada);
  
      // Set loading state and account number
      setIsLoading(false);
      
  
      // Set gauge data for the first post
      setGaugeData({
        vbat: postUnico.vbat,
        volt,
        i1m,
        xvolt,
        statusl: postUnico.status,
        charg: postUnico.charg,
        type: postUnico.type,
        sec: postUnico.sec,
        c_seg: postUnico.c_seg,
        c_marcha: postUnico.c_marcha,
        error: postUnico.error,
        cr: postUnico.cr,
      });
  
      // Fetch and process vista data
      const { data: vistasData } = await getVistasRequest();
      const vistasResult = vistasData.find(x => x.topiccuenta === cuenta.nroorden);
  
      if (vistasResult) {
        const dateUpdated = new Date(vistasResult.updatedAt);
        setDateLastUpdated(dateUpdated.toLocaleDateString('en-GB'));
        setTimeLastUpdated(dateUpdated.toLocaleTimeString('sv-SE'));
        setVistas(vistasResult);
      }
  
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };
  

  useEffect(() => { 
    fetchData(); // Initial fetch
      const timer = setInterval(fetchData, 10000); // Periodic fetch every 2 seconds  
      return () => clearInterval(timer); // Cleanup on component unmount
    }, []);
  
    const { statusl, charg, type, sec, c_seg, c_marcha, error, cr } = gaugeData;

    
    const seca = gaugeData.sec

  // Lógica para los bits de seguridad (según los valores de 'secs')
  let a1 = seca & (2 ** (9));
  let a2 = seca & (2 ** (10));
  let a3 = seca & (2 ** (11));
  let a4 = seca & (2 ** (12));
  let a5 = seca & (2 ** (13));
  let a6 = seca & (2 ** (14));
  
  //console.log('gauge', gaugeData)
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const PublishIOTon = async () => {     
    const mes = 'TVE/' + cuentaVar.nroorden + '/ping'
    const  message = '';   
    //console.log('press', mes, message)    
    await pubpush({mes, message})          
  } 

  const PublishIOToff = async () => {     
    const mes = 'TVE/' + cuentaVar.nroorden + '/ping'
    const  message = ''; 
    //console.log('press', mes, message)    
    await pubpush({mes, message})          
  } 
  

  return (
    <Box
        padding={2}
        maxWidth={1200}
        maxHeight={600}
    >
        <Paper elevation={4} square={false}>
            
            <Box
                //maxWidth={1200}
                //maxHeight={800}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                        <Box
                            //backgroundColor='#ffcdd2'
                            width={1200}
                            height={300} 
                            padding={2}                            
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                >
                                <Box
                                    backgroundColor='#ce93d8'
                                    width={400}
                                    height={300}                                
                                >
                                    <Card variant="outlined">
                                        {isLoading ? <Box backgroundColor='#e3f2fd' height={300}>
                                            <Box padding={10}
                                            >
                                                <Typography color="textSecondary" sx={{ fontSize: "18px", fontWeight: "800" }}>
                                                    Cargando eventos .........
                                                </Typography>
                                            </Box>                                            
                                        </Box>:<Box height={300}>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    sx={{
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                    }}
                                                    >

                                                        <BoxCuenta results={cuentaVar} />
                                                        <Box >
                                                            <Box marginLeft={1} marginY={2}>
                                                                <Typography color="textSecondary" sx={{ fontSize: "18px", fontWeight: "800" }}>
                                                                    
                                                                </Typography>
                                                            </Box>
                                                            <Box marginX={1} >
                                                                <Card variant="outlined" >
                                                                    <Box
                                                                        
                                                                        maxWidth={390}
                                                                        //backgroundColor='#e3f2fd'
                                                                    >
                                                                        <Box marginY={2} ></Box>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            sx={{
                                                                                justifyContent: "flex-start",
                                                                                alignItems: "center",
                                                                            }}
                                                                            >
                                                                                
                                                                                    <Box marginLeft={3}>
                                                                                        <Button 
                                                                                            variant="contained"
                                                                                            color="primary" 
                                                                                            startIcon={<HistoryEduOutlinedIcon />} 
                                                                                            size="small"
                                                                                            sx={{ 
                                                                                                width: 100, // Ajusta el ancho del botón para que sea pequeño
                                                                                                height: 30, // Ajusta la altura para que sea cuadrado
                                                                                                padding: 0, // Quitar padding interno adicional
                                                                                                minWidth: 0, // Elimina el ancho mínimo
                                                                                                borderRadius: 2, // Bordes ligeramente redondeados si lo prefieres
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                //console.log(params.id);
                                                                                                navigate(`/dashH/${params.id}`);
                                                                                            }} 
                                                                                            >Historico                                                  
                                                                                        </Button>
                                                                                    </Box>                                                                    
                                                                                    <Box paddingX={3}>
                                                                                        <Button 
                                                                                            variant="contained"
                                                                                            color="primary" 
                                                                                            startIcon={<HandymanOutlinedIcon />} 
                                                                                            size="small"
                                                                                            sx={{ 
                                                                                                width: 100, // Ajusta el ancho del botón para que sea pequeño
                                                                                                height: 30, // Ajusta la altura para que sea cuadrado
                                                                                                padding: 0, // Quitar padding interno adicional
                                                                                                minWidth: 0, // Elimina el ancho mínimo
                                                                                                borderRadius: 2, // Bordes ligeramente redondeados si lo prefieres
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                //console.log(params.id);
                                                                                                navigate(`/cue/${params.id}`);
                                                                                            }} 
                                                                                            >Trabajos                                                 
                                                                                        </Button>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Button 
                                                                                        variant="contained"
                                                                                        color="warning" 
                                                                                        startIcon={<EventNoteOutlinedIcon />} 
                                                                                        size="small"
                                                                                        sx={{ 
                                                                                            width: 100, // Ajusta el ancho del botón para que sea pequeño
                                                                                            height: 30, // Ajusta la altura para que sea cuadrado
                                                                                            padding: 0, // Quitar padding interno adicional
                                                                                            minWidth: 0, // Elimina el ancho mínimo
                                                                                            borderRadius: 2, // Bordes ligeramente redondeados si lo prefieres
                                                                                        }}
                                                                                        onClick={handleClickOpen} 
                                                                                        >ping                                                  
                                                                                        </Button>                                                                        
                                                                                    </Box>
                                                                                    
                                                                                    <Box marginTop={2} marginLeft={3}>
                                                                                        <Button 
                                                                                        variant="contained"
                                                                                        color="primary" 
                                                                                        startIcon={<MapOutlinedIcon />} 
                                                                                        size="small"
                                                                                        sx={{ 
                                                                                            width: 100, // Ajusta el ancho del botón para que sea pequeño
                                                                                            height: 30, // Ajusta la altura para que sea cuadrado
                                                                                            padding: 0, // Quitar padding interno adicional
                                                                                            minWidth: 0, // Elimina el ancho mínimo
                                                                                            borderRadius: 2, // Bordes ligeramente redondeados si lo prefieres
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            //console.log(params.id);
                                                                                            navigate(`/map/${cuentaVar.nroorden}`);
                                                                                        }} 
                                                                                        >Mapa                                                 
                                                                                        </Button>                                                                        
                                                                                    </Box>

                                                                                    <Box marginTop={2} marginLeft={3}>
                                                                                        <Button 
                                                                                        variant="contained"
                                                                                        color="primary" 
                                                                                        startIcon={<SettingsOutlinedIcon />} 
                                                                                        size="small"
                                                                                        sx={{ 
                                                                                            width: 100, // Ajusta el ancho del botón para que sea pequeño
                                                                                            height: 30, // Ajusta la altura para que sea cuadrado
                                                                                            padding: 0, // Quitar padding interno adicional
                                                                                            minWidth: 0, // Elimina el ancho mínimo
                                                                                            borderRadius: 2, // Bordes ligeramente redondeados si lo prefieres
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            //console.log(params.id);
                                                                                            navigate(`/set/${cuentaVar.nroorden}`);
                                                                                        }} 
                                                                                        >Setting                                                
                                                                                        </Button>                                                                        
                                                                                    </Box>
                                                                                
                                                                        </Grid>
                                                                        <Box marginY={2} ></Box>
                                                                    </Box>
                                                                </Card>
                                                            </Box>
                                                        </Box>
                                                </Grid>
                                            </Box>}                                        
                                    </Card>
                                </Box>    
                                <Box
                                    //backgroundColor='#ffcdd2'
                                    width={700}
                                    height={300}   
                                    paddingLeft={2}                              
                                >
                                            <Grid
                                                container
                                                direction="row"
                                                sx={{
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}
                                                >
                                                    <Card variant="outlined">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        sx={{
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                        >
                                                            <Grid container direction="row" sx={{ justifyContent: "left" }}>
                                                                <Corriente volt={gaugeData.volt} vbat={gaugeData.vbat} i1m={gaugeData.i1m} xvolt={gaugeData.xvolt} />
                                                            </Grid>
                                                            <Box><Typography>Fecha: {fecha}</Typography></Box>
                                                        </Grid>
                                                        
                                                        
                                                    </Card>
                                            

                                </Grid>

                                    
                                                                        
                                </Box>
                            </Grid>
                        </Box>


                        <Box
                            //backgroundColor='#e1bee7'
                            width={1200}
                            height={300}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                >
                                <Box
                                    //backgroundColor='#ffcdd2'
                                    width={400}
                                    height={500}                                
                                >
                                    <Card variant="outlined">
                                        <BoxSeguridad
                                            result={{
                                            date0: dateLastUpdated,
                                            date1: timeLastUpdated,
                                            a1, a2, a3, a4, a5, a6,
                                            statusl, charg, type, sec, c_seg, c_marcha, error, cr
                                            }}
                                        />                                       
                                    </Card>                                    
                                </Box>    
                                <Box
                                    //backgroundColor='#ffcdd2'
                                    width={700}
                                    maxHeight={500} 
                                    paddingLeft={2}                              
                                >
                                    <Card variant="outlined">
                                        <Box padding={2}><BoxJob cuentaBox={params.id} /></Box>
                                    </Card>                                    
                                </Box>
                            </Grid>
                        </Box>
                </Grid>                
            </Box>


            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Solicitar evento placa
              </DialogTitle>
              <DialogActions>
                <Grid
                  container
                  direction="row"
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 3
                  }}
                >
                  <Box paddingX={4}><Button onClick={PublishIOTon} variant="contained">Pedir evento</Button></Box>
                    
                    <Box><Button onClick={handleClose} variant="contained" autoFocus color='secondary'>Salir </Button></Box>
                </Grid>
              </DialogActions>
            </Dialog>                                









        </Paper>
    </Box>
  )
}


