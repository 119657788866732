import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { getHistoryMarcha } from '../../../api/history';
import { useNavigate, useParams } from "react-router-dom";

export function GraTiempo() {

  const cuentas = useSelector(selectCuenta);
  const params = useParams();
  const [march, setMarch] = useState([]); 
  const [fech, setFech] = useState([]);
  const cuenta = cuentas.filter(x => x._id === params.id);

  const fetchData = async () => {
    const his = await getHistoryMarcha(cuenta[0].nroorden);
    const fechasMap = new Map();  // Usamos Map para un acceso más rápido

    // Iteramos una sola vez sobre los datos
    for (let index = 0; index < his.data.length; index++) {
      const fecha = his.data[index];  // Obtener el objeto de fecha
      const fechaFormateada = dayjs(fecha.createdAt).format('MM/YYYY');  // Formatear la fecha en MM/YYYY
      let marcha = parseFloat(fecha.c_seg);   // Obtener el valor de c_marcha como número
  
      // Si la fecha no está en el Map o si el valor de c_marcha es mayor, actualizamos
      if (!fechasMap.has(fechaFormateada) || fechasMap.get(fechaFormateada) < marcha) {
        fechasMap.set(fechaFormateada, marcha);  // Guardamos la fecha y el valor máximo de c_marcha
      }
    }

    // Convertimos las claves y valores de fechasMap a arrays para las gráficas
    const grafecha = Array.from(fechasMap.keys());
    const gramarcha = Array.from(fechasMap.values());

    setMarch(gramarcha);
    setFech(grafecha);

    // Debugging
    console.log("Fechas: ", grafecha);
    console.log("Marchas: ", gramarcha);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Aquí creamos un array de colores para las barras
  const colors = [ "#33FF57", "#3357FF", "#F9A825", "#9C27B0", "#FFEB3B", "#00BCD4", "#8BC34A"];

  // Si tienes más barras que colores en el array, puedes repetir los colores de forma cíclica
  const cycleColors = march.map((_, index) => colors[index % colors.length]);

  // Debugging
  console.log("Colores: ", cycleColors);

  const series = [
    {
      name: "Vbat",
      data: march
    }
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 380
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication: 'end'
      }
    },
    xaxis: {
      categories: fech,
    },
    dataLabels: {
      enabled: true,  // Habilita los data labels en el gráfico
      style: {
        colors: ['#fff'],  // Color del texto de los labels (puedes cambiarlo a tu preferencia)
        fontSize: '12px',
        fontWeight: 'bold'
      },
      formatter: function(value) {
        return value;  // Muestra el valor de la barra (y) en los data labels
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    yaxis: {
      title: {
        text: 'segundos'
      }
    },
    colors: cycleColors, // Asignamos los colores cíclicamente
  };

  return <Chart options={options} series={series} type="bar" height={260} width={660} />;
};