import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const Menuitems = [
  {
    title: "Home",
    icon: HomeOutlinedIcon,
    href: "/home",
  },

  {
    title: "Cuentas",
    icon: AccountBalanceWalletOutlinedIcon,
    href: "/cuenta",
    subItems: [
      {title: "Nuevo",href: "/cuentaform", icon: HomeOutlinedIcon},
      {title: "Ubicacion",href: "/map", icon: MapOutlinedIcon},
      {title: "Historico",href: "/his", icon: HomeOutlinedIcon}
    ]
  },  
 
  
  {
    title: "Usuarios",
    icon: PersonOutlineOutlinedIcon,
    href: "/usu",
  },
  
 

  {
    title: "Visor",
    icon: AssignmentOutlinedIcon,
    href: "/vis",
  },
  
  
];

export default Menuitems;
