import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  Button,
  Stack,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { pubpush } from "../../api/mqtt";
import { useNavigate, useParams } from "react-router-dom";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import AccessibleOutlinedIcon from "@mui/icons-material/AccessibleOutlined";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import FlashAutoOutlinedIcon from "@mui/icons-material/FlashAutoOutlined";
import SettingsRemoteOutlinedIcon from "@mui/icons-material/SettingsRemoteOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";

// Componente reutilizable para cada input
const InputField = ({
  label,
  icon,
  value,
  onChange,
  onSubmit,
  activeInput,
  fieldName,
  isSwitch = false, // Nuevo prop para manejar si es un switch
  isSelect = false, // Nuevo prop para manejar si es un select
}) => (
  <Box width={300}>
    <Card sx={{ marginLeft: 1, marginTop: 1 }}>
      <Stack
        spacing={1.5}
        direction="row"
        alignItems="center"
        paddingX={1}
        paddingTop={2}
        paddingBottom={1}
      >
        <Button
          variant="soft"
          color="neutral"
          startIcon={icon}
          onClick={onSubmit}
          disabled={activeInput && activeInput !== fieldName}
          sx={{
            padding: "4px 10px",
            fontSize: "10px",
            height: "28px",
            textTransform: "none",
          }}
        >
          {label}
        </Button>
        {isSwitch ? (
          <FormControlLabel
            control={
              <Switch
                checked={value === 1} // Comprobamos si el valor es 1 para activar el switch
                onChange={(e) =>
                  onChange(e, fieldName, e.target.checked ? 1 : 0)
                } // Enviamos 1 si es true, 0 si es false
                color="primary"
                disabled={activeInput && activeInput !== fieldName}
              />
            }
            label={label}
          />
        ) : isSelect ? (
          <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
              value={value}
              onChange={(e) => onChange(e, fieldName, e.target.value)}
              disabled={activeInput && activeInput !== fieldName}
              sx={{
                fontSize: "12px", // Ajusta el tamaño de la fuente
                textAlign: "center", // Centra el texto
                "& .MuiSelect-select": {
                  paddingTop: "4px", // Ajusta el padding superior
                  paddingBottom: "20px", // Ajusta el padding inferior si es necesario
                  display: "flex",
                  alignItems: "center", // Asegura que el texto esté centrado verticalmente
                  height: "20px", // Puedes ajustar la altura del select si es necesario
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "4px", // Bordes redondeados si lo prefieres
                },
              }}
            >
              <MenuItem value="SR">SR</MenuItem>
              <MenuItem value="PO">PO</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <TextField
            label={label}
            variant="outlined"
            fullWidth
            value={value}
            onChange={(e) => onChange(e, fieldName)}
            disabled={activeInput && activeInput !== fieldName}
            sx={{
              width: 180,
              fontSize: "6px",
              height: "22px",
              "& .MuiInputBase-root": {
                height: "24px",
                display: "flex",
                justifyContent: "center",
              },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderRadius: "4px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "12px",
                top: "-10px",
              },
              "& .MuiInputBase-input": {
                textAlign: "left",
                padding: "-10px",
              },
            }}
          />
        )}
      </Stack>
    </Card>
  </Box>
);

export function SettingPage() {
  const initialState = {
    name: "",
    iot: 0, // Cambié el valor de iot a 0 (false) por defecto
    passiot: "",
    username: "",
    ssid: "",
    pass: "",
    port: "1883",
    interval_int: "",
    p_int: 0,
    bz: 0,
    f_alarm: 0,
    type: "",
    enable: 1,
    calib_i: 0,
    alta_cr: "",
    borra_cr: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [activeInput, setActiveInput] = useState(null);
  const [pubText, setPubText] = useState("");
  const [nameVar, setNameVar] = useState(null);
  const params = useParams();
  //console.log("mappage", params.id);

  const handleInputChange = useCallback((event, fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value !== undefined ? value : event.target.value, // Usamos el valor del switch si es necesario
    }));
    console.log(fieldName, event.target.value);

    setActiveInput(fieldName);
  }, []);

  const handleSubmit = useCallback(() => {
    const fieldValue = formData[activeInput];
    console.log("handle", fieldValue);

    if (fieldValue !== undefined && fieldValue !== null) {
      setPubText(fieldValue); // Guardamos el valor del campo activo
      //console.log(activeInput, fieldValue); // Ver en consola
      setNameVar(activeInput);

      setFormData(initialState); // Resetear el formulario
    }
    setActiveInput(null); // Limpiar el campo activo
  }, [activeInput, formData]);

  const PublishIOTon = async () => {
    const mes = "TVE/" + params.id + "/setting";
    const message = nameVar + ":" + pubText;
    console.log("press", mes, message);
    await pubpush({ mes, message });
  };

  const PublishPing = async () => {
    const mes = "TVE/" + params.id + "/ping";
    const message = "";
    console.log("press", mes, message);
    await pubpush({ mes, message });
  };

  const PublishFabrica = async () => {
    const mes = "TVE/" + params.id + "/setting";
    const message = "fabrica";
    console.log("press", mes, message);
    await pubpush({ mes, message });
  };

  const PublishCancelar = async () => {
    setFormData(initialState);
    setActiveInput(null); // Limpiar el campo activo
  };

  useEffect(() => {
    if (pubText) {
      // Realizar el envío solo si pubText tiene un valor
      console.log("Enviando datos: ", nameVar, pubText);
      PublishIOTon();
    }
  }, [pubText]);

  const inputFields = [
    {
      label: "Nombre",
      icon: <DriveFileRenameOutlineOutlinedIcon />,
      fieldName: "name",
    },
    {
      label: "Iot",
      icon: <CloudDoneOutlinedIcon />,
      fieldName: "iot",
      isSwitch: true,
    }, // Cambié a isSwitch: trueen el
    {
      label: "Password Iot",
      icon: <PasswordOutlinedIcon />,
      fieldName: "passiot",
    },
    {
      label: "Usuario",
      icon: <PersonOutlineOutlinedIcon />,
      fieldName: "username",
    },
    { label: "SSID", icon: <WifiOutlinedIcon />, fieldName: "ssid" },
    {
      label: "Password SSID",
      icon: <PasswordOutlinedIcon />,
      fieldName: "pass",
    },
    {
      label: "Puerto",
      icon: <VideogameAssetOutlinedIcon />,
      fieldName: "port",
    },
    {
      label: "Intervalo",
      icon: <HourglassEmptyOutlinedIcon />,
      fieldName: "interval_int",
    },
    {
      label: "Parada intermedia",
      icon: <PanToolOutlinedIcon />,
      fieldName: "p_int",
      isSwitch: true,
    },
    {
      label: "Habilita sonido",
      icon: <VolumeUpOutlinedIcon />,
      fieldName: "bz",
      isSwitch: true,
    },
    {
      label: "Alarma",
      icon: <NotificationsActiveOutlinedIcon />,
      fieldName: "f_alarm",
      isSwitch: true,
    },
    {
      label: "Tipo", // Aquí he hecho el cambio para que sea un selector
      icon: <AccessibleOutlinedIcon />,
      fieldName: "type",
      isSelect: true, // Indicamos que es un select
    },
    {
      label: "Habilitar", // Aquí he hecho el cambio para que sea un selector
      icon: <ThumbUpOffAltOutlinedIcon />,
      fieldName: "enable",
      isSwitch: true,
    },
    {
      label: "Calibrar corriente", // Aquí he hecho el cambio para que sea un selector
      icon: <FlashAutoOutlinedIcon />,
      fieldName: "calib_i",
      isSwitch: true,
    },
    {
      label: "Alta Control",
      icon: <SettingsRemoteOutlinedIcon />,
      fieldName: "alta_cr",
    },
    {
      label: "Borra Control",
      icon: <SettingsRemoteOutlinedIcon />,
      fieldName: "borra_cr",
    },
  ];

  return (
    <Grid
      container
      direction="column"
      sx={{
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        padding: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#ff4081",
          padding: 2,
          borderRadius: 1,
          boxShadow: 2,
        }}
      >
        <Typography color="white" sx={{ fontSize: "24px", fontWeight: "600" }}>
          Control de equipo
        </Typography>
      </Box>

      {/* Campos de entrada */}
      <Grid
        container
        direction="row"
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        {inputFields.map(({ label, icon, fieldName, isSwitch, isSelect }) => (
          <Box
            key={fieldName}
            sx={{
              width: 300,
              margin: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 70, // Aquí defines la altura específica para que sea igual en ambos Box
            }}
          >
            <InputField
              key={fieldName}
              label={label}
              icon={icon}
              value={formData[fieldName]}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
              activeInput={activeInput}
              fieldName={fieldName}
              isSwitch={isSwitch}
              isSelect={isSelect} // Agregamos isSelect aquí
            />
          </Box>
        ))}
      </Grid>

      {/* Espacio inferior */}
      <Box
        sx={{
          marginTop: 5,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            //backgroundColor: "#f44336",
            padding: 2,
            marginRight: 2,
            borderRadius: 1,
            width: "100px",
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<NearMeOutlinedIcon />}
            size="small"
            sx={{
              width: 120, // Ajusta el ancho del botón para que sea pequeño
              height: 30, // Ajusta la altura para que sea cuadrado
              padding: 2, // Quitar padding interno adicional
              minWidth: 0, // Elimina el ancho mínimo
              borderRadius: 2, // Bordes ligeramente redondeados si lo prefieres
            }}
            onClick={() => {
              PublishPing();
            }}
          >
            Ping
          </Button>
        </Box>

        <Box
          sx={{
            //backgroundColor: "#f44336",
            padding: 2,
            marginRight: 2,
            borderRadius: 1,
            width: "100px",
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<NearMeOutlinedIcon />}
            size="small"
            sx={{
              width: 120, // Ajusta el ancho del botón para que sea pequeño
              height: 30, // Ajusta la altura para que sea cuadrado
              padding: 2, // Quitar padding interno adicional
              minWidth: 0, // Elimina el ancho mínimo
              borderRadius: 2, // Bordes ligeramente redondeados si lo prefieres
            }}
            onClick={() => {
              PublishFabrica();
            }}
          >
            Fabrica
          </Button>
        </Box>

        <Box
          sx={{
            //backgroundColor: "#f44336",
            padding: 2,
            marginRight: 2,
            borderRadius: 1,
            width: "100px",
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<NearMeOutlinedIcon />}
            size="small"
            sx={{
              width: 120, // Ajusta el ancho del botón para que sea pequeño
              height: 30, // Ajusta la altura para que sea cuadrado
              padding: 2, // Quitar padding interno adicional
              minWidth: 0, // Elimina el ancho mínimo
              borderRadius: 2, // Bordes ligeramente redondeados si lo prefieres
              backgroundColor: "#FF5722", // Color naranja
              "&:hover": {
                backgroundColor: "#E64A19", // Color naranja más oscuro cuando se pasa el mouse
              },
            }}
            onClick={() => {
              PublishCancelar();
            }}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}
