import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getVistasRequest } from "../../api/vista";
import { getAlarmasRequest } from "../../api/alarma"; 
import { getCuentasRequest } from "../../api/cuenta";
import { Card, CardContent, Box, Typography } from "@mui/material";
import { HomeTable } from './Table/HomeTable';
import CircularProgress from '@mui/material/CircularProgress';

export function MonitorPage() {
  const [tablas, setTabla] = useState([]);
  const [ alarma, setAlarma] = useState([]);
  const prevTablasRef = useRef(); // Usamos useRef para evitar comparar objetos en cada renderizado
  const navigate = useNavigate();
  const [ evento, setEvento] = useState([]);
  
  const fetchData = async () => {
    try {
      // Fetch data concurrently
      const [vistasResponse] = await Promise.all([        
        getAlarmasRequest(),
      ]);

      let eventos = vistasResponse.data;

      //console.log(eventos);

     
      eventos = eventos.map(event => {
        if (event.createdAt) {
          // Formatea la fecha de createdAt al formato en-GB
          event.createAtFormatted = new Date(event.createdAt).toLocaleString("en-GB");
        }
        return event;
      });
      
      eventos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); 


      const groupedByTopic = eventos.reduce((acc, event) => {
        // Si no existe un evento en el acumulador para este topic o el evento actual es más reciente, reemplaza
        if (!acc[event.topic] || new Date(event.createdAt) > new Date(acc[event.topic].createdAt)) {
          acc[event.topic] = event;
        }
        return acc;
      }, {});
      const eventosRecientes = Object.values(groupedByTopic);

      
      console.log('evento', eventosRecientes);
      setEvento(eventosRecientes);

    } catch (error) {
      console.error("Error en fetchData:", error);
    }
  };

  useEffect(() => {
    const fetchInterval = setInterval(fetchData, 5000);
    return () => clearInterval(fetchInterval);
  }, []); 

  // Filtrar eventos donde ackA no es true
  const alarmaEve = evento.filter(x => x.ackA !== 'true');


  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Box sx={{ overflow: { xs: "auto", sm: "unset" } }}>
            <Box sx={{ overflow: { xs: "auto", sm: "unset" } }}>
              {alarmaEve.length > 0 ? (
                <Box><HomeTable alarmas={alarmaEve} /></Box>
              ) : (
                <Box sx={{ height: 300, marginTop: 30, marginLeft: 50 }}>
                  <Typography color="textSecondary" sx={{ fontSize: "18px", fontWeight: "800" }}>
                    Cargando eventos .........
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
