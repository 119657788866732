import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Paper, Typography, Card, CardContent } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { useNavigate, useParams } from "react-router-dom";
import GraHistory  from './Graph/GraHistorico';
import dayjs from 'dayjs';
import { GraMarcha }from './GraMarcha/GraMarcha';
import { GraTiempo }from './GraMarcha/GraTiempo';
import { getHistoryEvent, getHistoryMarcha } from '../../api/history';


export function DashHistory() {

    const params = useParams();      
    const cuentas = useSelector(selectCuenta);
    const cuenta = cuentas.filter(x => x._id === params.id);
    const [gaugeData, setGaugeData] = useState({});
    const [march, setMarch] = useState([]); 
    const [sec, setSec] = useState([]);
    const [fech, setFech] = useState([]);
    const [fechs, setFechs] = useState([]);
    const [state, setState] = useState({
        series: [],
        options: {
          chart: {
            id: 'chart2',
            type: 'line',
            height: 230,
            dropShadow: {
              enabled: true,
              enabledOnSeries: [1]
            },
            toolbar: {
              autoSelected: 'pan',
              show: false
            }
          },
          legend: {
            show: false,  // Esto elimina la leyenda
          },
          colors: ['#008FFB', '#00E396'],
          stroke: {
            width: [2, 2],
            curve: ['straight', 'monotoneCubic']
          },
          fill: {
            opacity: [1, 0.75],
          },
          markers: {
            size: 0
          },
          yaxis: [        
            {
              seriesName: 'Vbat',
              axisTicks: {
                show: true,
                color: '#008FFB'
              },
              axisBorder: {
                show: true,
                color: '#008FFB'
              },
              labels: {
                style: {
                  colors: '#008FFB',
                }
              },
              title: {
                text: "Vbat",
                style: {
                  color: '#008FFB'
                }
              },
              min: 0,
              max: 30,
              tickAmount: 5
            },
            {
              seriesName: 'Corriente',
              opposite: true,
              axisTicks: {
                show: true,
                color: '#00E396'
              },
              axisBorder: {
                show: true,
                color: '#00E396'
              },
              
              labels: {
                style: {
                  colors: '#00E396'
                }
              },
              title: {
                text: "Corriente",
                style: {
                  color: '#00E396'
                }
              },
              min: 0,
              max: 4,
              tickAmount: 5
            }
          ],
          xaxis: {
            categories: [] ,// Asignaremos aquí los valores de Xdata
            labels: {
              style: {
                fontSize: '10px' // Aquí reducimos el tamaño de la fuente del eje X
              },
              rotate: -45, // Gira las etiquetas si es necesario para más espacio
                formatter: (value, index) => {
                  // Mostrar cada N-ésima etiqueta
                  return index % 10 === 0 ? value : ''; // Salta las etiquetas entre cada 5
                }
            }
          },
        },   
    
        seriesLine: [{
          name: 'Vbat',
          data: []
        }, {
          name: 'Corriente',
          data: []
        }],
        optionsLine: {
          chart: {
            id: 'chart1',
            height: 130,
            type: 'area',
            brush: {
              target: 'chart2',
              enabled: true
            },
            selection: {
              enabled: true,
            },
          },
          colors: ['#008FFB', '#00E396'],
          stroke: {
            width: [1, 2],
            curve: ['straight', 'monotoneCubic']
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.91,
              opacityTo: 0.1,
            }
          },
          legend: {
            show: false,  // Esto elimina la leyenda
          },
          xaxis: {
            tooltip: {
              enabled: false
            },
            labels: {
              style: {
                fontSize: '6px' // Aquí reducimos el tamaño de la fuente del eje X
              },
              rotate: -45, // Gira las etiquetas si es necesario para más espacio
                formatter: (value, index) => {
                  // Mostrar cada N-ésima etiqueta
                  return index % 10 === 0 ? value : ''; // Salta las etiquetas entre cada 5
                }
            }
          },
          yaxis: [
            {
              seriesName: 'Vbat',
              min: 0,
              max: 30,
              tickAmount: 2,
            },
            {
              seriesName: 'Corriente',
              opposite: true,
              min: 0,
              max: 4,
              tickAmount: 5
            }
          ]         
        }
      });

    const fetchData = async () => {          
        const his = await getHistoryEvent(cuenta[0].nroorden);
        const Vbat = [];
        const i1m = [];
        const xvi = [];
        const dataMFinal = his.data.length;    
        //console.log(dataMFinal)    
        if (dataMFinal > 2) {
          for (let i = 0; i < 300; i++) {
            const item = his.data[i];
            Vbat.push(item.vbat);
            i1m.push(item.ic);
            const fecha = new Date(item.createdAt);
            let horasArgentina = fecha.getUTCHours() - 3;
            if (horasArgentina < 0) {
              horasArgentina += 24;
            }
            const horas = horasArgentina.toString().padStart(2, '0');
            const minutos = fecha.getUTCMinutes().toString().padStart(2, '0');
            const horaFormateada = `${horas}:${minutos}`;
            const dia = fecha.getUTCDate().toString().padStart(2, '0');
            const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, '0');
            const ano = fecha.getUTCFullYear();
            const dosUltimosDigitosAno = ano % 100;
            const fechaFormateada = `${dia}/${mes}/${dosUltimosDigitosAno}`;
            xvi.push(fechaFormateada + ' ' + horaFormateada);
          }
        }
        setGaugeData({
          Vbat: Vbat.reverse(),
          i1m: i1m.reverse(),
          xvi: xvi.reverse(),
        });
      }

      const fetchDataI = async () => {
        try {
          // Verificar que 'cuenta' y 'cuenta[0].nroorden' estén definidos
          if (cuenta && cuenta[0] && cuenta[0].nroorden) {
            const his = await getHistoryMarcha(cuenta[0].nroorden);
            //console.log('his',his)      
            // Verificar que his.data sea un arreglo antes de intentar iterar
            if (his && his.data && Array.isArray(his.data)) {
              const fechasMap = new Map();      
              // Iteramos sobre los datos del historial
              for (let index = 0; index < his.data.length; index++) {
                const fecha = his.data[index];      
                // Validar si 'createdAt' y los valores numéricos están presentes
                if (fecha && fecha.createdAt && !isNaN(fecha.c_marcha) ) {
                  const fechaFormateada = dayjs(fecha.createdAt).format('MM/YYYY');
                  const marcha = parseFloat(fecha.c_marcha);
                  // Actualizamos el Map si la fecha no está o si el valor es mayor
                  if (!fechasMap.has(fechaFormateada) || fechasMap.get(fechaFormateada) < marcha) {
                    fechasMap.set(fechaFormateada, marcha);
                  }                 
                }
              }      
              // Convertimos las claves y valores de fechasMap a arrays para las gráficas
              const grafecha = Array.from(fechasMap.keys());
              const gramarcha = Array.from(fechasMap.values());      
              // Actualizamos el estado con los datos procesados
              setFech(grafecha);
              setMarch(gramarcha);              
            } else {
              console.error("Error en los datos de la respuesta de la API", his);
            }
            {/*    */}
          } else {
            console.error("cuenta[0] o nroorden no están definidos", cuenta);
          }
        } catch (error) {
          console.error("Error al obtener o procesar los datos:", error);
        }
      };

      const fetchDataII = async () => {
        try {
          // Verificar que 'cuenta' y 'cuenta[0].nroorden' estén definidos
          if (cuenta && cuenta[0] && cuenta[0].nroorden) {
            const his = await getHistoryMarcha(cuenta[0].nroorden);
            //console.log('his',his)      
            // Verificar que his.data sea un arreglo antes de intentar iterar
            if (his && his.data && Array.isArray(his.data)) {
              const fechasMap = new Map();      
              // Iteramos sobre los datos del historial
              for (let index = 0; index < his.data.length; index++) {
                const fecha = his.data[index];      
                // Validar si 'createdAt' y los valores numéricos están presentes
                if (fecha && fecha.createdAt && !isNaN(fecha.c_seg)) {
                  const fechaFormateada = dayjs(fecha.createdAt).format('MM/YYYY');
                  var segundo = parseFloat(fecha.c_seg);

                  

                  // Actualizamos el Map si la fecha no está o si el valor es mayor
                  if (!fechasMap.has(fechaFormateada) || fechasMap.get(fechaFormateada) < segundo) {
                    fechasMap.set(fechaFormateada, segundo);
                  }                 
                }
              }      
              // Convertimos las claves y valores de fechasMap a arrays para las gráficas
              const grafecha = Array.from(fechasMap.keys());
              const grasegundo = Array.from(fechasMap.values());    
              
              
              // Actualizamos el estado con los datos procesados
              setFechs(grafecha)
              setSec(grasegundo);              
            } else {
              console.error("Error en los datos de la respuesta de la API", his);
            }
            {/*    */}
          } else {
            console.error("cuenta[0] o nroorden no están definidos", cuenta);
          }
        } catch (error) {
          console.error("Error al obtener o procesar los datos:", error);
        }
      };

      //console.log(sec)
      useEffect(() => {
        fetchData();
        fetchDataI();
        fetchDataII();
      }, []);
      useEffect(() => {
        if (gaugeData.Vbat && gaugeData.xvi && gaugeData.Vbat.length > 0 && gaugeData.xvi.length > 0) {
          const updatedState = {
            ...state,
            series: [{
              name: 'Vbat',
              data: gaugeData.Vbat.map(Number) // Asegúrate de que sean numéricos
            }, {
              name: 'Corriente',
              data: gaugeData.i1m
            }],
            options: {
              ...state.options,
              xaxis: {
                categories: gaugeData.xvi // Asignar valores de Xdata
              },
            },
            seriesLine: [{
              name: 'Vbat',
              data: gaugeData.Vbat
            }, {
              name: 'Corriente',
              data: gaugeData.i1m
            }],
            optionsLine: {
              ...state.optionsLine,
              xaxis: {
                categories: gaugeData.xvi // Asignar valores de Xdata
              },
            }
          };
          setState(updatedState);
        }
      }, [gaugeData]);

      //console.log(state)

    
  return (

    <Box>
      <Grid
        container
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      ><Card variant="outlined" >
          <Box
            //backgroundColor='#ffcdd2'
            width={1200}
            height={400}           
          >          
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
                <Box></Box>
                <Box>
                  <GraHistory state={{ state}} gaugeData={{gaugeData}}/>
                </Box>
                <Box></Box>            
            </Grid>                   
          </Box>
        </Card> 
        <Box          
          width={1200}
          height={200} 
         marginTop={1}
        >
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card variant="outlined" >
              <Box 
                //backgroundColor='#ce93d8'
                width={593}
                height={200} 
                //marginRight={1}
                //padding={2}            
                >
                  
                  <GraMarcha params={{ march, fech }}/>
                  {/* */}
              </Box> 
            </Card>
            <Box 
            //backgroundColor='#ce93d8'
            width={10}
            height={200} 
            //marginRight={1}
            //padding={2}            
            ></Box> 
           <Card variant="outlined" >
              <Box 
                //backgroundColor='#ce93d8'
                width={593}
                height={200} 
                //marginRight={1}
                //padding={2}            
                >
                  
                 <GraTiempo params={{ sec, fechs }}/> 
                 {/**/}
                  </Box>
            </Card>
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}


