import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getVistasRequest } from "../../api/vista";
import { getCuentasRequest } from "../../api/cuenta";
import { Card, CardContent, Box, Typography } from "@mui/material";
import { HomeTable } from './Table/HomeTable';
import CircularProgress from '@mui/material/CircularProgress';

export function HomePage() {
  const [tablas, setTabla] = useState([]);
  const prevTablasRef = useRef(); // Usamos useRef para evitar comparar objetos en cada renderizado
  const navigate = useNavigate();
  
  const fetchData = async () => {
    try {
      // Fetch data concurrently
      const [cuentasResponse, vistasResponse] = await Promise.all([
        getCuentasRequest(),
        getVistasRequest(),
      ]);
  
      // Filter habilitado accounts and prepare a lookup for 'vista'
      const cuentaVisor = cuentasResponse.data.filter(x => x.habilitado === "habi");
      const vistaLookup = vistasResponse.data.reduce((acc, item) => {
        acc[item.topiccuenta] = item;
        return acc;
      }, {});
  
      const fechaActual = Date.now(); // Get current time once
  
      const datos = cuentaVisor.map(cuenta => {
        const vistaTabla = vistaLookup[cuenta.nroorden] || {};
  
        // Calculate time difference and background color
        const updatedAt = vistaTabla.updatedAt ? new Date(vistaTabla.updatedAt) : null;
        const fechaActualizacion = updatedAt ? updatedAt.toLocaleString("en-GB") : 0;
        const diferenciaTiempo = updatedAt ? fechaActual - updatedAt.getTime() : 0;
  
        const backcell = vistaTabla.status === "ENCENDIDO" ? "#69f0ae" : "secondary";
        const difcell = diferenciaTiempo < 8000000 ? "#69f0ae" : "#ff5722";

        //console.log(cuenta.nroorden, diferenciaTiempo)
  
        return {
          cuentaId: cuenta._id || 0,
          cuentaTabla: cuenta.nroorden || 0,
          nombreTabla: cuenta.nombre || 0,
          fecha: fechaActualizacion,
          vbat: vistaTabla.vbat || 0,
          statust: vistaTabla.status || 0,
          charg: vistaTabla.charg || 0,
          ic: vistaTabla.ic || 0,
          type: vistaTabla.type || 0,
          sec: vistaTabla.sec || 0,
          c_seg: vistaTabla.c_seg || 0,
          c_marcha: vistaTabla.c_marcha || 0,
          error: vistaTabla.error || '',
          cr: vistaTabla.cr || 0,
          dif: diferenciaTiempo,
          backcells: backcell,
          difcells: difcell,
          
        };
      });
  
      // Avoid redundant state updates
      if (!prevTablasRef.current || !areArraysEqual(prevTablasRef.current, datos)) {
        setTabla(datos);
        prevTablasRef.current = datos;
      }
  
    } catch (error) {
      console.error("Error en fetchData:", error);
    }
  };
  
  // Utility function to compare arrays more efficiently (shallow check could be enough)
  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return false;
    }
    return true;
  };
  

  let cont = 0;
  for (let index = 0; index < tablas.length; index++) {
      if (tablas[index].error !== '') {
          cont = cont + 1;
      }
  }
  console.log(tablas);

  // Manejo del intervalo y limpieza
  useEffect(() => {
    const fetchInterval = setInterval(fetchData, 2000);
    return () => clearInterval(fetchInterval);
  }, []); // El intervalo se ejecutará solo una vez al montar el componente

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Box sx={{ overflow: { xs: "auto", sm: "unset" } }}>
            {tablas.length > 0 ? (
              <HomeTable tablas={tablas} />
            ) : (
              <Box sx={{ height: 300, marginTop: 30, marginLeft: 50 }}>
                <Typography color="textSecondary" sx={{ fontSize: "18px", fontWeight: "800" }}>
                  Cargando eventos .........
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
