import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Chip, IconButton, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";

const tableHeaderStyle = {
  fontSize: "14px",
  fontWeight: "800",
  color: "textSecondary",
};

const tableCellStyle = {
  fontSize: "10px",
  fontWeight: "500",
};

const TableHeader = () => {
  const headers = [
    "Ir",
    "Cuenta",    
    "Nombre",
    "Vbat",
    "Cargador",
    "Corr",    
    "Tipo",
    "Tiempo",
    "Veces",
    "Error",
    "Estado",
    "Online",
  ];

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableCell key={header}>
            <Typography sx={tableHeaderStyle}>{header}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableRowData = ({ data, navigate }) => (
  <TableRow key={data.cuentaTabla}>
    <TableCell>
      <IconButton aria-label="navigate" onClick={() => navigate(`/dash/${data.cuentaId}`)}>
        <EastIcon />
      </IconButton>
    </TableCell>
    {[data.cuentaTabla, data.nombreTabla, data.vbat, data.charg, data.ic, data.type, data.c_seg, data.c_marcha, data.error].map((value, index) => (
      <TableCell key={index}>
        <Typography sx={tableCellStyle}>{value}</Typography>
      </TableCell>
    ))}
    <TableCell>
      <Chip
        sx={{
          pl: "4px",
          pr: "4px",
          backgroundColor: data.backcells,
          color: "#1a237e",
          fontSize: "10px",
        }}
        size="small"
        label={data.statust}  
      />
    </TableCell>
    <TableCell>
      <Chip
        sx={{
          pl: "4px",
          pr: "4px",
          backgroundColor: data.difcells,
          color: "#1a237e",
          fontSize: "10px",
        }}
        size="small"
        label={data.fecha}
      />
    </TableCell>
  </TableRow>
);

export const HomeTable = ({ tablas }) => {
  const navigate = useNavigate();
  
  // Estado para manejar el filtro de 'status'
  const [statusFilter, setStatusFilter] = useState("");

  // Función para manejar el cambio del filtro
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  // Filtrar los datos según el filtro de "status"
  const filteredTablas = statusFilter
    ? tablas.filter((x) => x.statust === statusFilter)  // Filtramos por el 'statust'
    : tablas;

  // Obtener los diferentes valores de "status" para el filtro
  const statusOptions = [...new Set(tablas.map((x) => x.statust))];  // Usamos 'statust' para obtener los valores únicos

  return (
    <>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Filtrar por Estado</InputLabel>
        <Select value={statusFilter} onChange={handleStatusFilterChange} label="Filtrar por Estado">
          <MenuItem value="">Todos</MenuItem>
          {statusOptions.map((status, index) => (
            <MenuItem key={index} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Table
        aria-label="simple table"
        sx={{
          mt: 3,
          whiteSpace: "nowrap",
        }}
      >
        <TableHeader />
        <TableBody>
          {filteredTablas.map((x) => (
            <TableRowData key={x.cuentaTabla} data={x} navigate={navigate} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};
