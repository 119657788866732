import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { getHistoryEvent } from '../../../api/history';
import { Box, Grid, Button, Paper, Typography, Card, CardContent } from "@mui/material";

const GraHistorico = ({ state, gaugeData }) => {
  //console.log(state.state.options)

  // Verifica si gaugeData está vacío antes de intentar renderizar el gráfico
  if (!gaugeData.gaugeData.Vbat || !gaugeData.gaugeData.xvi) {
    return <div>Cargando informacion...</div>; // Mostrar un mensaje de carga si no hay datos
  }
  
  return (
    <div>
          <div id="wrapper">
            {/* Primer gráfico: Gráfico principal */}
            <div id="chart-line2">
              <ReactApexChart options={state.state.options} series={state.state.series} type="line" height={240} width={1140}/>
            </div>
    
            {/* Segundo gráfico: Gráfico de selección */}
            <div id="chart-line">
              <ReactApexChart options={state.state.optionsLine} series={state.state.seriesLine} type="area" height={170} width={1140}/>
            </div>
          </div>
          <div id="html-dist"></div>
        </div>
  );
};

export default GraHistorico;
