import axios from './axios';

export const getAlarmasRequest = () => axios.get('/alarm');

export const getAlarmaRequest = (id) => axios.get(`/alarm/${id}`);

//export const getAlarmaRequestUser = () => axios.get('/cuentasuser');

export const createAlarmaRequest = (alarma) => axios.post('/alarm', alarma);

export const updateAlarmaRequest = (id, alarma) => axios.put(`/alarm/${id}`, alarma);

//export const deleteCuentaRequest = (id) => axios.delete(`/cuentas/${id}`);