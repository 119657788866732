import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, Button, Grid } from "@mui/material";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { updateAlarmaRequest } from '../../../api/alarma';

const tableHeaderStyle = {
  fontSize: "14px",
  fontWeight: "800",
  color: "textSecondary",
};

const tableCellStyle = {
  fontSize: "10px",
  fontWeight: "500",
};

export const HomeTable = ({ alarmas }) => {
  const navigate = useNavigate();
  const [selectedAlarmId, setSelectedAlarmId] = useState(null);
  const [open, setOpen] = useState(false);

  const alarmaReal = alarmas.filter(alarm => alarm.ackA === true)

  // Función para abrir el diálogo y guardar el alarm_id
  const handleClickOpen = (alarmId) => {
    setSelectedAlarmId(alarmId); // Guardamos el alarm_id de la fila seleccionada
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setAlarm = async () => {
    const values = { ackA: false };
    console.log(selectedAlarmId, values)
    await updateAlarmaRequest(selectedAlarmId, values);  // Usamos el alarm_id
  };

  const TableRowData = ({ data, navigate }) => (
    <TableRow key={data.cuentaTabla}>
      <TableCell>
        <IconButton aria-label="navigate" onClick={() => navigate(`/dash/${data.cuentaId}`)}>
          <EastIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        <IconButton aria-label="navigate" onClick={() => handleClickOpen(data._id)} style={{ color: 'red' }}>
          <NotificationsActiveOutlinedIcon />
        </IconButton>
      </TableCell>
      {/* Nueva columna para la fecha */}
      <TableCell>
        <Typography sx={tableCellStyle}>{ (data.createAtFormatted).toLocaleString("en-GB") }</Typography> {/* Fecha */}
      </TableCell>
      {/* Nueva columna para la hora y minutos */}
      
      {[data.id, data.topiccuenta, data.topicnombre, data.vbat, data.charg, 
        data.ic, data.type, data.c_seg, data.c_marcha, data.error, data.status, data.ackA
      ].map((value, index) => (
        <TableCell key={index}>
          <Typography sx={tableCellStyle}>{value}</Typography>
        </TableCell>
      ))}      
    </TableRow>
  );

  const TableHeader = () => {
    const headers = [
      "Ir",      
      "ACK",
      "Fecha",      // Nueva columna para la fecha
      
      "id",
      "Cuenta",
      "Nombre",
      "Vbat",
      "Charg",
      "Corr",    
      "Tipo",
      "Tiempo",
      "Veces",
      "Error",
      "Estado"      
    ];
  
    return (
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>
              <Typography sx={tableHeaderStyle}>{header}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <Box>
        <Table
          aria-label="simple table"
          sx={{
            mt: 3,
            whiteSpace: "nowrap",
          }}
        >
          <TableHeader />
          <TableBody>
            {alarmaReal.map((x) => (
              <TableRowData key={x.topiccuenta} data={x} navigate={navigate} />
            ))}
          </TableBody>        
        </Table>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Desea reconocer el evento?
        </DialogTitle>
        <DialogActions>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: 3
            }}
          >
            <Box><Button onClick={setAlarm} variant="outlined" >Si</Button></Box>
            
            <Box marginLeft={6}><Button onClick={handleClose} variant="outlined" autoFocus color='secondary'>Salir </Button></Box>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
