import React from 'react';
import { useSelector } from 'react-redux';
import { selectPost } from '../../features/post/postSlide';
import { useEffect, useState } from "react";
import { Box, Button, Card, Grid, TextField, useColorScheme, useTheme, Typography, MenuItem, Paper } from "@mui/material";
import { 
    GridRowModes,
    DataGrid, 
    GridToolbar,
    GridToolbarContainer, 
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import { DataGridPro } from '@mui/x-data-grid-pro'; 
  
  export function VisorPage() {
   
    const [rowId, setRowId] = useState(null);
    const posts = useSelector(selectPost)
    const postVisor = posts.filter(x => ( x._id !== "1" ))    
    const LengthR = (postVisor.length)
    const postVisor1 = postVisor.slice((LengthR,LengthR-2000))
 

    const columns = [    
      {
        field: "createdAt",
        headerName: "Fecha",
        valueFormatter: params => new Date(params?.value).toLocaleString('en-GB'),
        width:170,      
      },
      {
        field: "topiccuenta",
        headerName: "# orden",
        width:68,  
        align:"center",   
      },           
      {
        field: "id",
        headerName: "ID",
        width:50,  
        align:"left",     
      },  
      {
        field: "vbat",
        headerName: "Bateria",
        width:70,  
        align:"left",     
      }, 
      {
        field: "status",
        headerName: "Estado",
        width:150,  
        align:"center",     
      },   
      {
        field: "sec",
        headerName: "Sec",
        width:70,  
        align:"left",     
      },
      {
        field: "charg",
        headerName: "Carga",
        width:150,  
        align:"left",     
      }, 
      {
        field: "error",
        headerName: "Error",
        width:150,  
        align:"left",     
      }, 
      {
        field: "ic",
        headerName: "Corr",
        width:50,  
        align:"center",     
      }, 
      {
        field: "c_marcha",
        headerName: "Veces",
        width:50,  
        align:"center",     
      }, 
      {
        field: "c_seg",
        headerName: "Seg",
        width:80,  
        align:"center",     
      },      
    ]

    const [sortModel, setSortModel] = useState([
      {
        field: 'createdAt',
        sort: 'desc',
      },
    ]);

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"        
        marginTop={2}
        >
            <Box>Numero de eventos: {postVisor1.length}</Box>
            <Paper elevation={0} square={false} >
                
                <Box
                    marginX={5}
                    marginY={1}
                    height={600}
                    width={1100}
                  >
                  
                    <DataGridPro
                        rows={postVisor1} 
                        columns={columns}  
                        rowHeight={18}
                        getRowId={(row) => row._id} 
                        sortModel={sortModel}                        
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    />
                    
                </Box>
            </Paper>
    </Grid>
    )
  }
  
 